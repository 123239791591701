import { zodResolver } from "@hookform/resolvers/zod";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";
import { BusinessDropdown } from "Components/Shared/SelectorsForm/BusinessDropdown";
import {
  BusinessInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { useAres } from "Components/Onboarding/BusinessEntity/Hooks/useAres";
import { PartyDto, PartyType } from "Infrastructure/Api/Api";
import { Grid } from "@mui/material";
import { businessAddressValidationSchema } from "Components/Onboarding/schemas";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { useIsCurrentUserHunter } from "Hooks/useIsCurrentUserHunter";

type ExtendedBusinessInformationModel = BusinessInformationModel & {
  hasHunterNoIdentificationNumberYet?: boolean;
};

export const validationSchema: z.ZodType<ExtendedBusinessInformationModel> = z
  .object({
    partyType: z.nativeEnum(PartyType),
    identificationNumber: z
      .string()
      .regex(/^\d{8}$/, "IČO musí být číslo o délce 8 znaků..")
      .optional(),
    companyName: z.string().optional(),
    address: businessAddressValidationSchema,
    contact: z.object({
      phone: z.string().min(12, "Prosím, zadejte platné telefonní číslo"),
    }),
    hasHunterNoIdentificationNumberYet: z.boolean().optional(),
  })
  .superRefine(
    (
      {
        hasHunterNoIdentificationNumberYet,
        identificationNumber,
        address,
        companyName,
      },
      ctx,
    ) => {
      if (!hasHunterNoIdentificationNumberYet && !identificationNumber) {
        ctx.addIssue({
          path: ["identificationNumber"],
          message: "IČO musí být číslo o délce 8 znaků.",
          code: "custom",
        });
      }

      if (!hasHunterNoIdentificationNumberYet) {
        if (!companyName) {
          ctx.addIssue({
            path: ["companyName"],
            message: "Název subjektu je povinný.",
            code: "custom",
          });
        }
        if (!address.descriptionNumber) {
          ctx.addIssue({
            path: ["address", "descriptionNumber"],
            message: "Číslo popisné je povinné.",
            code: "custom",
          });
        }
        if (!address.city) {
          ctx.addIssue({
            path: ["address", "city"],
            message: "Město je povinné.",
            code: "custom",
          });
        }
        if (!address.postalCode) {
          ctx.addIssue({
            path: ["address", "postalCode"],
            message: "PSČ je povinné.",
            code: "custom",
          });
        }
      }
    },
  );

export const BusinessInformationStep = () => {
  const { t } = useResource();
  const isUserHunter = useIsCurrentUserHunter();
  const { nextStep, getFormValues, setAresData } = useOnboardingContext();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
    setValue,
    trigger,
  } = useForm<ExtendedBusinessInformationModel>({
    defaultValues: getFormValues(OnboardingSteps.BusinessInformation),
    resolver: zodResolver(validationSchema),
  });

  const onAresData = (aresData: PartyDto | null) => {
    setAresData(aresData);
    reset(getFormValues(OnboardingSteps.BusinessInformation));
  };

  const { isFetchingAres } = useAres({
    initialIdentifierNumber: getFormValues(OnboardingSteps.BusinessInformation)
      ?.identificationNumber,
    partyType: watch("partyType"),
    identifierNumber: watch("identificationNumber"),
    onAresData,
    disabled: !isDirty,
  });

  const hasHunterNoIdentificationNumberYet = watch(
    "hasHunterNoIdentificationNumberYet",
  );

  useEffect(() => {
    if (hasHunterNoIdentificationNumberYet) {
      setValue("identificationNumber", undefined);
      setValue("companyName", undefined);
      setValue("address.street", undefined);
      setValue("address.descriptionNumber", undefined);
      setValue("address.orientationNumber", undefined);
      setValue("address.city", undefined);
      setValue("address.postalCode", undefined);

      trigger([
        "identificationNumber",
        "companyName",
        "address.street",
        "address.descriptionNumber",
        "address.orientationNumber",
        "address.city",
        "address.postalCode",
      ]);
    }
  }, [hasHunterNoIdentificationNumberYet, setValue, trigger]);

  const onSubmit = (data: ExtendedBusinessInformationModel) => {
    const { hasHunterNoIdentificationNumberYet, ...model } = data; // take out the hasHunterNoIdentificationNumberYet field, as it's used only for validation
    const formData: BusinessInformationModel = model;
    nextStep(formData);
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox submitButtonLabel="Pokračovat" submitDisabled={isFetchingAres}>
        <ValuesBox title="Základní údaje" hideDivider>
          <BusinessDropdown
            control={control}
            name="partyType"
            label="Forma podnikání"
          />
          <div>
            <FormInput
              control={control}
              name="identificationNumber"
              label="IČO"
              disabled={hasHunterNoIdentificationNumberYet}
              placeholder={hasHunterNoIdentificationNumberYet ? "-" : undefined}
            />
            {isUserHunter && (
              <CheckboxInput
                label="Ještě nemám IČO"
                control={control}
                name="hasHunterNoIdentificationNumberYet"
              />
            )}
          </div>
          <FormInput
            control={control}
            name="companyName"
            label="Název subjektu"
            disabled={hasHunterNoIdentificationNumberYet}
          />
        </ValuesBox>

        <ValuesBox title="Sídlo" hideDivider>
          <FormInput
            control={control}
            name="address.street"
            label="Ulice"
            disabled={hasHunterNoIdentificationNumberYet}
          />
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.descriptionNumber"
                label={t(Resources.Common.DescriptionNumber)}
                disabled={hasHunterNoIdentificationNumberYet}
              />
            </Grid>
            <Grid item xs={6}>
              <FormInput
                control={control}
                name="address.orientationNumber"
                disabled={hasHunterNoIdentificationNumberYet}
                label={t(Resources.Common.OrientationNumber)}
              />
            </Grid>
          </Grid>
          <FormInput
            control={control}
            name="address.city"
            label="Město"
            disabled={hasHunterNoIdentificationNumberYet}
          />
          <FormInput
            control={control}
            name="address.postalCode"
            label="PSČ"
            mask="000 00"
            disabled={hasHunterNoIdentificationNumberYet}
          />
        </ValuesBox>

        <ValuesBox title="Kontakt" hideDivider>
          <FormInput
            control={control}
            name="contact.phone"
            label={t(Resources.Common.Phone)}
            mask="+000 000 000 000"
            inputProps={{
              inputMode: "tel",
            }}
          />
        </ValuesBox>
      </StepBox>
    </FullHeightForm>
  );
};
