import { AddressType, UserDetailModel } from "Components/Onboarding/types";
import {
  AverageTransactionValue,
  BankEnvironment,
  BusinessActivity,
  ExpectedMonthlyRevenue,
  LicenseCode,
  PartyType,
  RelationType,
  SourceOfIncome,
} from "Infrastructure/Api/Api";

//Warning: change order of steps or add new steps with caution, logic in onboarding context depends on it
export enum OnboardingSteps {
  BusinessInformation,
  IncomeInformation,
  PersonalDocument,
  UserDetail,
  
  AdditionalInformation, //legal entity only
  
  BankAccountConnection, //non-hunter only
  ManualAccountVerification, //non-hunter only
  
  LicenseSelection, //hunter only
  LicensePayment, //hunter only
  ManualLicensePayment //hunter only
}

export enum LicenseCountryCode {
  CZ = "CZ",
  SK = "SK",
}

export type LicenseSelectionModel = {
  license: LicenseCode;
  countryAlpha2Code: LicenseCountryCode;
} & (
  | { isUsingPrivateWallet?: boolean; privateWalletAddress?: string }
  | {
      isUsingPrivateWallet: true;
      privateWalletAddress: string;
    }
);

export type LicensePaymentModel = {
  environment: BankEnvironment;
};

export type BusinessInformationModel = {
  partyType: PartyType;
  identificationNumber?: string;
  companyName?: string;
  address: AddressType;
  contact: {
    phone: string;
  };
};

export type IncomeInformationModel = {
  businessActivity: BusinessActivity;
  otherBusinessActivityDetails?: string;
  incomeSource: SourceOfIncome;
  incomeSourceDetail?: string;
  expectedMonthlyRevenue?: ExpectedMonthlyRevenue;
  averageTransactionValue?: AverageTransactionValue;
  taxResidence: number;
  relationType?: RelationType;
  isPoliticallyExposed: boolean;
};

export type PersonalDocumentModel = {
  firstDocument: {
    frontSide: File;
    backSide: File;
  };
  secondDocument: {
    frontSide: File;
  };
};

export type LastStepModel = {
  phone: string;
  politicallyExposed: boolean;
};

export type AdditionalInformationModel = {
  isOwner: boolean;
  owners?: {
    publicID: string;
  }[];
};

export type OwnerType = {
  firstName: string;
  lastName: string;
  birthDate: Date;
  nationality: number;
  address: AddressType;
};

export type FormModel = {
  [OnboardingSteps.LicenseSelection]: LicenseSelectionModel;
  [OnboardingSteps.LicensePayment]: LicensePaymentModel;
  [OnboardingSteps.BusinessInformation]: BusinessInformationModel;
  [OnboardingSteps.IncomeInformation]: IncomeInformationModel;
  [OnboardingSteps.PersonalDocument]: PersonalDocumentModel;
  [OnboardingSteps.UserDetail]: UserDetailModel;
  [OnboardingSteps.AdditionalInformation]: AdditionalInformationModel;
  [OnboardingSteps.BankAccountConnection]: unknown;
  [OnboardingSteps.ManualAccountVerification]: unknown;
  [OnboardingSteps.ManualLicensePayment]: unknown;
};
