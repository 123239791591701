import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { OnboardingLayout } from "Components/Layout/OnboardingLayout";
import { BusinessEntityOnboarding } from "Components/Onboarding/BusinessEntity/BusinessEntityOnboarding";
import { OnboardingContextProvider as BusinessOnboardingContextProvider } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { OnboardingContextProvider as NaturalOnboardingContextProvider } from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { NaturalPersonOnboarding } from "Components/Onboarding/NaturalPerson/NaturalPersonOnboarding";
import { logError } from "ErrorService";
import { useIsCurrentUserHunter } from "Hooks/useIsCurrentUserHunter";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { PartyType } from "Infrastructure/Api/Api";
import { FC } from "react";

export const OnboardingPage: FC = () => {
  const user = useLoggedInUser();
  const isHunter = useIsCurrentUserHunter();
  const { data, isLoading } = usePartyQuery(user?.partyPublicID);

  // TODO: loading indicator
  if (isLoading) {
    return (
      <OnboardingLayout title="">
        <div>Loading</div>
      </OnboardingLayout>
    );
  }

  // TODO: handle error
  if (!data) {
    logError(new Error("No user party data"));
    return (
      <OnboardingLayout title="Chyba aplikace">
        <div>Došlo k neočekávané chybě</div>
      </OnboardingLayout>
    );
  }

  // TODO: after api return PartyType
  if (data.type !== PartyType.NaturalPerson) {
    return (
      <BusinessOnboardingContextProvider isHunter={isHunter}>
        <BusinessEntityOnboarding />
      </BusinessOnboardingContextProvider>
    );
  }

  return (
    <NaturalOnboardingContextProvider>
      <NaturalPersonOnboarding />
    </NaturalOnboardingContextProvider>
  );
};
