import { zodResolver } from "@hookform/resolvers/zod";
import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { useLicenseQuery } from "Api/Queries/Licenses/useLicenseQuery";
import { usePartyLicensesQuery } from "Api/Queries/Licenses/usePartyLicensesQuery";
// import { CountrySelector } from "Components/Onboarding/BusinessEntity/Components/CountrySelector/CountrySelector";
import { LicensePicker } from "Components/Onboarding/BusinessEntity/Components/LicensePicker/LicensePicker";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import {
  LicenseCountryCode,
  LicenseSelectionModel,
} from "Components/Onboarding/BusinessEntity/types";
import {
  getNextAvailableLicense,
  transformLicenseToDefaultValues,
} from "Components/Onboarding/BusinessEntity/Utils/licenseUtils";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { LicenseCode } from "Infrastructure/Api/Api";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z
  .object({
    license: z.nativeEnum(LicenseCode),
    countryAlpha2Code: z.nativeEnum(LicenseCountryCode),
    isUsingPrivateWallet: z.boolean().optional(),
    privateWalletAddress: z.string().optional(),
  })
  .refine(
    data => {
      // If isUsingPrivateWallet is checked, privateWalletAddress must not be empty
      return (
        !data.isUsingPrivateWallet ||
        (data.privateWalletAddress &&
          data.privateWalletAddress.trim().length > 0)
      );
    },
    {
      message: "U vlastní peněženky musíte vyplnit její adresu.",
      path: ["privateWalletAddress"], // Path to the field with the error
    },
  );

const DEFAULT_COUNTRY_ALPHA2_CODE: LicenseCountryCode = LicenseCountryCode.CZ;

export const LicenseSelectionStep = () => {
  const user = useLoggedInUser();
  const {
    data: existingLicenses,
    isLoading,
    isError,
  } = usePartyLicensesQuery(user?.partyPublicID);

  const {
    data: { items: licenses } = {},
    isLoading: isLicensesLoading,
    isError: isLicensesError,
  } = useLicenseQuery(DEFAULT_COUNTRY_ALPHA2_CODE);

  if (isLoading || isLicensesLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (isError || isLicensesError || !licenses) {
    return <>Omlouváme se, něco se pokazilo. Zkuste to prosím později.</>;
  }

  const existingLicense = existingLicenses?.items?.[0];
  const defaultLicense = getNextAvailableLicense(licenses);
  const defaultValues: Partial<LicenseSelectionModel> = existingLicense
    ? transformLicenseToDefaultValues(existingLicense)
    : {
        license: defaultLicense?.code,
        countryAlpha2Code: DEFAULT_COUNTRY_ALPHA2_CODE,
      };

  return (
    <LicenseSelectionStepContent
      defaultValues={defaultValues}
      hasExistingLicense={!!existingLicense}
    />
  );
};

type LicenseSelectionStepContentProps = {
  defaultValues: Partial<LicenseSelectionModel>;
  hasExistingLicense?: boolean;
};

const LicenseSelectionStepContent = ({
  defaultValues,
  hasExistingLicense = false,
}: LicenseSelectionStepContentProps) => {
  const { nextStep } = useOnboardingContext();

  const { control, handleSubmit, watch, setValue } =
    useForm<LicenseSelectionModel>({
      defaultValues,
      resolver: zodResolver(schema),
    });

  const onSubmit = (data: LicenseSelectionModel) => {
    nextStep(data);
  };

  const isUsingPrivateWallet = watch("isUsingPrivateWallet");
  const currentCountryAlpha2Code = watch("countryAlpha2Code");
  const currentLicense = watch("license");

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel={
          hasExistingLicense ? "Zaplatit" : "Objednat a zaplatit"
        }
        submitButtonDescription={
          <Typography textAlign={"justify"}>
            Objednáním a zaplacením licence uzavírám{" "}
            <a href="#">Hunterskou smlouvu</a> s LEGI.ONE LTD., odštěpný závod,
            jejíž součástí jsou tyto <a href="#">Související podmínky</a>.
          </Typography>
        }
      >
        Koupí licence získáváte právo stát se hunterem pro Legi.one.
        {/* TODO: Temporarily hiding the countries sections, only supporting CZ for now */}
        {/* <ValuesBox title={"Země"} withTopMargin>
          <CountrySelector control={control} isDisabled={hasExistingLicense} />
        </ValuesBox> */}
        <ValuesBox title={"Licence"} withTopMargin>
          <Typography>
            {"Vyberte verzi licence, kterou si chcete zakoupit."}
          </Typography>
          {defaultValues.license && currentLicense ? (
            <LicensePicker
              isDisabled={hasExistingLicense}
              onSelect={license => setValue("license", license)}
              defaultValue={defaultValues.license}
              currentValue={currentLicense}
              countryAlpha2Code={currentCountryAlpha2Code}
            />
          ) : (
            <>
              Pro zvolenou zemi již nejsou k dispozici žádné dostupné licence.
            </>
          )}
        </ValuesBox>
        <Divider sx={{ mt: "1rem" }} />
        <ValuesBox>
          <CheckboxInput
            disabled={hasExistingLicense}
            label={"Chci poslat do vlastní peněženky"}
            control={control}
            name="isUsingPrivateWallet"
          />
          {isUsingPrivateWallet && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography>
                Zadejte adresu své peněženky na Polygon blockchain.
              </Typography>
              <FormInput
                control={control}
                name="privateWalletAddress"
                label="Adresa peněženky"
                disabled={hasExistingLicense}
                type="text"
              />
            </Box>
          )}
        </ValuesBox>
        <Divider sx={{ mt: "1rem" }} />
        {/* <Typography>
          Objednáním a zaplacením licence uzavírám <a href="#">Hunterskou smlouvu</a> s LEGI.ONE
          LTD., odštěpný závod, jejíž součástí jsou tyto <a href="#">Související podmínky</a>.
        </Typography> */}
      </StepBox>
    </FullHeightForm>
  );
};
