import { useMutation } from "@tanstack/react-query";
import { USERS_LIST_QUERY_KEY } from "Api/Queries/User/useUsersListQuery";
import {
  getVerificationsQueryKey,
  VERIFICATIONS_QUERY_KEY,
} from "Api/Queries/Verifications/useVerificationsQuery";
import { queryClient } from "App";
import {
  postVerifications,
  putVerifications,
  type UserVerificationUpdateDto,
} from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useVerificationsMutation = ({ onSuccess, onError }: Props) =>
  useMutation({
    mutationFn: processResponse(() => postVerifications()),
    onSuccess,
    onError,
  });

type UsePutVerificationsMutationProps = {
  onSuccess?: (verificationData: UserVerificationUpdateDto) => void;
  onError?: () => void;
};

export const usePutVerificationsMutation = ({
  onSuccess,
  onError,
}: UsePutVerificationsMutationProps) =>
  useMutation({
    mutationFn: processResponse((verifications: UserVerificationUpdateDto) =>
      putVerifications(verifications),
    ),
    onSuccess: (_, data: UserVerificationUpdateDto) => {
      queryClient.invalidateQueries({
        queryKey: data.requestedUserID
          ? getVerificationsQueryKey(data.requestedUserID)
          : VERIFICATIONS_QUERY_KEY,
      });
      queryClient.invalidateQueries({
        queryKey: [USERS_LIST_QUERY_KEY],
      });
      onSuccess?.(data);
    },
    onError,
  });
